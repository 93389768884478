<template>
  <CForm ref="form" class="needs-validation">
    <CInput
      label="Return Shipping Company: "
      horizontal
      type="text"
      autocomplete="text"
      v-model="data.shippingCompany"
      required
    />

    <CInput
      label="Order Number: "
      horizontal
      :disabled="!data.shippingCompany"
      type="text"
      autocomplete="text"
      v-model="data.orderNo"
      required
    />

    <CButton style="width: 100%" color="success" :disabled="!data.orderNo" @click="getOrderInfo">Get Order Info</CButton>

    <div class="orderSummary" v-show="Object.keys(orderInfo).length > 0">
      <div> 
        <span> Order Status: </span> {{ orderInfo.orderStatus }}
        <span v-if="orderInfo.orderStatus == 'DELIVERED'" style="color: green">( This shipment is return eligible ) </span>
        <span v-else style="color: red"> (This shipment is not return eligible, please check again or inform admin. Contact e-mail address info@kaft.com) </span>

      </div>
      <div> <span> Order Status Change: </span> {{ orderInfo.orderChangeLogStatus }}  </div>
      <br />
      <div> 

        <span>Customer Info: </span>
        <p> 
          Name: {{ orderInfo.userName }} <br />
          Country: {{ orderInfo.country }} <br />
          Order Date: {{ orderInfo.orderDate }} <br />
        </p> 
        
      </div>
      <CInput
          label="Valid Date"
          horizontal
          type="date"
          v-model="data.validDate"
          required
      />
    </div>

  </CForm>
</template>

<script>
export default{
  name: "OrderWarehouseReturnForm",
  props:{
    validated: Boolean,
    params: Object,
    actionType: String,
  },
  computed:{
    orderInfo: function(){
      return this.$store.getters.warehouseReturnOrderInfo
    }
  },
  watch:{
    params: function(val){
      this.data = {...val};
    },
    actionType:  function(val){
      if(val == 'create'){
        this.data = {...this.params};
      }
    },
  },
  data: function(){
    return{
      data: this.params ? {...this.params} : {},

    }
  },
  methods:{
    getOrderInfo: async function(){
      let data = new FormData();
      data.append('orderId', this.data.orderNo)
      await this.$store.dispatch('order_getOrderwarehouseReturn', data)
    }
  }
}
</script>

<style type="text/css" scoped> 
  .orderSummary{
    margin-top: 50px;
  }
  .orderSummary span{
      font-weight: bold;
    }
</style>